<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        </b-row>
      </div>
      <div class="parent-container">

        <div class="table table-striped container-con">

          <div class="container-1">

            <draggable
              id="first"
              v-model="ImagesList"
              @change="onUnpublishedChange"
            >
              <template
                v-for="element in ImagesList"
                class="list-group-item item"
              >

                <div class="row-ele">
                  <b-card
                    no-body
                    class="mb-0"
                  >
                  
                  <b-row>
                  
<b-col   cols="8" class="img-col">
                    <b-media
                      no-body
                      vertical-align="center"
                      class="flex-column"
                    >
                      <b-media-aside>
                        <b-img
                          ref="refPreviewE2"

                          :src="`https://api.lmitac.com/${element.image}`"
                          height="135"
                          width="150"
                          class="rounded mr-2 mb-1 mb-md-0"
                        />
                      </b-media-aside>
                      <b-media-body />
                    </b-media>
                  </b-col>
                  <b-col   cols="2" class="action-col">
                    <a>
                      <router-link

                        :to="`/update-image/${ element.id}`"
                        style="margin: 0px 12px"
                      >
                        <feather-icon icon="EditIcon" />
                      </router-link>
                    </a>
                    <a
                      v-if="$can('delete', 'image')"
                      style="margin: 0px 12px"
                      @click="DeleteItem(element.id)"
                    >
                      <feather-icon icon="TrashIcon" />

                    </a>
                    <p >
                   
                    {{ element.order }}-
                  </p>
                  </b-col>
                  </b-row>
                  </b-card>
                </div>

              </template>
            </draggable>
          </div>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
            >

              <span class="text-muted">
                Total 
                {{ dataMeta.of }} entries
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalImages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BDropdownItemButton,
  BImg,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  name: 'TableExample',
  display: 'Table',
  order: 8,
  components: {
    draggable,
    // useCousresList,
    BImg,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BCardText,
    BMediaAside,
    BMediaBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,

    vSelect,
  },
  setup() {
    const ImagesList = ref([])
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(100)
    const totalImages = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')

    const tableColumns = [

      { label: ' Image', key: 'image' },
      { key: 'actions' },

    ]
    watch([currentPage, perPage], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalImages.value,
      }
    })
    const meta = reactive({})
    const refetchData = () => {
      store.dispatch('gallery/Allimages', {

        per_page: perPage.value,
        page: currentPage.value,
      })
        .then(response => {
          console.log('response', response.data)

          console.log('totalImages', response.data.data.total)
          ImagesList.value = response.data.data.data
          totalImages.value = response.data.data.total
        })
    }

    refetchData()
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',

        },
      }).then(result => {
        if (result.value) {
          store.dispatch('gallery/DeletImg', id)
            .then(response => {
              refetchData()
              Vue.swal({
                title: 'Image Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const list = ref([
      { id: 1, name: 'Abby', sport: 'basket' },
      { id: 2, name: 'Brooke', sport: 'foot' },
      { id: 3, name: 'Courtenay', sport: 'volley' },
      { id: 4, name: 'David', sport: 'rugby' },
    ])
    const onUnpublishedChange = e => {
      let id = null
      let order = null

      if (e.added) {
        id = e.added.element.id
        order = e.added.element.order
        console.log('added')
        console.log(id)
        console.log(order)
      }
      if (e.moved) {
        const payload = []
        id = e.moved.element.id
        order = e.moved.element.order
        console.log('moved')
        console.log(id)
        console.log(order)
        const allData = FillterData(ImagesList.value)
        console.log(allData)
        allData.forEach(el => {
          payload.push({
            id: el.id,

            order: el.order,
          })
        })
        const formData = new FormData()
        formData.append('newOrders', JSON.stringify(payload))
        store.dispatch('gallery/updateOrder', formData)
          .then(response => {
            Vue.swal({
              title: 'Image Order Updated ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      } else if (e.removed) {
        id = e.removed.element.id
        order = e.removed.element.order
        console.log('removed')
        console.log(id)
        console.log(order)
      }
    }
    // filter data by Order
    const FillterData = data => {
      if (!data) return []

      if (data.length > 1) {
        for (let i = 0; i < data.length; i++) {
          for (let j = i + 1; j < data.length; j++) {
            console.log(data[i].order)
            console.log(i + 1)
            data[i].order = i + 1
            data[j].order = j + 1
          }
        }
      }

      return data
    }
    return {
      FillterData,
      onUnpublishedChange,
      list,
      tableColumns,
      ImagesList,
      DeleteItem,
      meta,
      refetchData,
      perPageOptions,
      totalImages,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
#first {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  justify-items: center;
  text-align: center;
  position: static;
              overflow-y: scroll;
            height: 100%;
}
.list-group-item {
  margin-left: 100px;

  width: 100% !important;
}
.container-1{
            position: relative;
            top:0px;
            overflow-y: scroll;
            overflow-y: hidden;
            height: calc(100% - 82px);
          
          }
          .container-con{
            position: static;
              // overflow-y: scroll;
            height: 500px;
          }
          .container-1 > div {
            display: inline-block;
            vertical-align: top;
          }
          .left-nav{
            position: fixed;
            width: 100px;
          }
          .center-nav{
            margin-left: 100px;
            width: 250px;
            height: calc(100% - 10px);
            overflow-y: scroll;
            border: 1px solid blue;

          }element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.col-1 {
    padding-right: 7rem;
    padding-left: 3rem !important;
}
.sticky {
              padding: 0 16px;
              background: #555;
              color: #f1f1f1;
              position: fixed;
              top: 0;
              width: 100%;
              height: 80px;

            }
 .parent-container {
                width: inherit;
                height: inherit;
                margin: 0;
                padding: 0;

            }
.row-ele {
    margin-bottom: 19px;
    height: 150px;
    margin: 2px;
    /* padding: 10px; */
}
img.rounded.mr-2.mb-1.mb-md-0 {
  margin: 2px;

}
.card.mb-0 {
  height: 100% !important;
    display: block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
